export const getCourtsidePremierCarouselData = () => {
  return [
    {
      carouselImages: [
        // "courtsidePremier/Carousel/courtside-10.jpg",
        "courtsidePremier/Carousel/courtside-01.jpg",
        "courtsidePremier/Carousel/courtside-02.jpg",
        "courtsidePremier/Carousel/courtside-03.jpg",
        "courtsidePremier/Carousel/courtside-04.jpg",
        "courtsidePremier/Carousel/courtside-05.jpg",
        "courtsidePremier/Carousel/courtside-06.jpg",
        "courtsidePremier/Carousel/courtside-07.jpg",
        "courtsidePremier/Carousel/courtside-08.jpg",
        "courtsidePremier/Carousel/courtside-09.jpg",
        "courtsidePremier/Carousel/courtside-11.jpg",
      ],
      title: "Courtside Premier",
      bottomLinks: [
        {
          title: "gallery",
          link: "modal",
          icon: "gallery",
        },
        // {
        //   title: "appointment",
        //   link: "appointment",
        //   icon: "appointment",
        // },
      ],
    },
  ];
};
