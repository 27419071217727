<template>
  <div id="CourtsidePremier">
    <HeroCarousel :heroCarouselData="heroCarouselData" />
    <div class="courtside-gradient" />
    <TicketPackages
      :ticketPackagesHeader="ticketPackagesHeader"
      :packages="packages"
      :ticketPackageImages="ticketPackageImages"
      :activeSection="'courtside'"
    />
    <b-container class="courtside-container">
      <div class="divider" />
      <h2>
        Elevated<br />
        <span>Experiences</span>
      </h2>
      <p>
        Courtside Premier guests will have access to unrivaled experiences.
        Depending on the booked session, experiences can include exclusive
        access to behind the scenes tours, photos with the trophy and chalk
        talks with tennis legends.
      </p>
      <div class="postscript">
        Benefits and experiences subject to change at the discretion of the
        USTA.
      </div>
    </b-container>
  </div>
</template>

<script>
import HeroCarousel from "@/components/patterns/HeroCarousel";
import { getCourtsidePremierCarouselData } from "@/data/HeroCarousel/courtsidePremierData";
import TicketPackages from "@/components/patterns/TicketPackages";
import axios from "axios";

export default {
  name: "CourtsidePremier",
  components: {
    HeroCarousel,
    TicketPackages,
  },
  data() {
    return {
      heroCarouselData: getCourtsidePremierCarouselData(),
      ticketPackagesHeader: {
        header:
          "<p>This is the pinnacle hospitality experience at the US Open featuring prime courtside seating in the front rows, elevated food & beverage, behind the scenes access, and dedicated service throughout your time at the tournament.</p>",
      },
      packages: null,
      // packages2: {
      //   cuisineOverlay: [],
      //   drinkOverlay: [],
      //   pageDescription:
      //     "<p>This is the pinnacle hospitality experience at the US Open featuring prime courtside seating in the front rows, elevated food & beverage, behind the scenes access, and dedicated service throughout your time at the tournament.</p>",
      //   theDetails: [
      //     {
      //       description: "Dedicated Concierge service",
      //     },
      //     {
      //       description: "Special Grounds and Stadium Entrance",
      //     },
      //     {
      //       description:
      //         "Parking Pass or Chauffeur Car Service (depending on session)",
      //     },
      //   ],
      //   theCuisine: [
      //     {
      //       description: "Reserved table in the 1968 Room",
      //       link: "/1968room",
      //     },
      //     {
      //       description: "Chef's table hospitality in the Player Cafe",
      //     },
      //     {
      //       description: "In-Seat beverage and snack service",
      //     },
      //   ],
      //   theView: [
      //     {
      //       description:
      //         "Prime Courtside Seats in the closest rows to the court",
      //     },
      //     {
      //       description:
      //         "Watch the world's greatest tennis players compete from mere feet away",
      //     },
      //     {
      //       description:
      //         "Close proximity to the Player Cafe and 1968 Room with access to Club Level exclusive shop and amenities",
      //     },
      //   ],
      // },
      ticketPackageImages: [
        "courtsidePremier/courtside_access.jpg",
        "courtsidePremier/courtside_hospitality.jpg",
        "courtsidePremier/courtside_view.jpg",
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    this.purl = this.$store.state.epitch.purl;

    var axel = Math.random() + "";
    var a = axel * 10000000000000;
    var axel2 = Math.random() + "";
    var b = axel2 * 10000000000000;

    let floodlightTag = document.createElement("script");
    let floodlightTag2 = document.createElement("script");
    floodlightTag.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
        a +
        "?"
    );
    floodlightTag2.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=" +
        b +
        "?"
    );
    document.body.appendChild(floodlightTag);
    document.body.appendChild(floodlightTag2);

    let pages = this.$store.state.epitch.ePitch.pages;
    pages.forEach((page) => {
      if (page.id === "12") {
        this.packages = JSON.parse(page.copy);
      }
    });

    if (this.$store.state.epitch.ePitch.mode === "live") {
      const purl = this.$store.state.epitch.ePitch.purl;
      const epitchUrl = this.$store.state.epitch.url;
      axios.post(`${epitchUrl}/epitchApi/EpitchLiteTracking.php`, {
        method: "page",
        purl: purl,
        presentationId: window.presentationId,
        sessionDbId: window.sessionDbId,
        section: 12,
      });
    }

    let copy = this.$store.state.epitch.ePitch.pages;
    if (typeof copy !== "undefined") {
      copy.forEach((page) => {
        if (page.id === "12") {
          let description = JSON.parse(page.copy);
          let pageDescription = description.pageDescription;
          if (pageDescription) {
            this.ticketPackagesHeader.header = pageDescription;
          }
          // window.console.log('description', pageDescription)
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#CourtsidePremier {
  display: flex;
  flex-direction: column;
  .courtside-gradient {
    width: 100%;
    height: 17px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #e1e1e1;
  }
  .courtside-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    font-family: "interstate", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    text-transform: uppercase;
    color: $text-grey;
    letter-spacing: 0;
    text-align: center;
    line-height: 23px;
    margin: 50px 0 0 0;
    span {
      font-weight: 700;
      color: $text-black;
    }
  }
  p {
    font-family: "interstate", sans-serif;
    font-weight: 500;
    color: $text-grey;
    line-height: 35px;
    display: block;
    text-align: center;
    padding: 50px 0;
    width: 80%;
  }
  .postscript {
    font-family: "interstate", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: $text-grey;
    font-style: italic;
    line-height: 35px;
    display: block;
    text-align: center;
  }
}
</style>